import { useTranslations } from 'next-intl';

import { Button, ModalBody, ModalFooter, ModalHeader, showNotification } from '@org/ui';

import type { ClientModalContentProps } from '..';

export const DeleteClientModalContent = ({
  disclosureState,
  selectedClient,
  deleteClient,
}: ClientModalContentProps) => {
  const t = useTranslations();
  const { clientName = '', id } = selectedClient;
  const { onClose } = disclosureState;

  return (
    <>
      <ModalHeader>
        {t('common.contextMenu.modalDelete.title', {
          name: clientName,
          object: '',
        })}
      </ModalHeader>
      <ModalBody>{t('common.contextMenu.modalDelete.label')}</ModalBody>
      <ModalFooter>
        <Button
          className="!rounded-md"
          onClick={onClose}
          size="md"
          variant="secondary"
        >
          {t('common.btnCancel')}
        </Button>
        <Button
          className="!rounded-md"
          isDisabled={!clientName}
          onClick={async () => {
            try {
              await deleteClient(id!);
              onClose?.();
            } catch {
              showNotification(
                'warning',
                t('main.clients.clientsOverviewPanel.alerts.warning.general'),
              );
            }
          }}
          size="md"
          variant="primary"
        >
          {t('common.contextMenu.modalDelete.btnDelete')}
        </Button>
      </ModalFooter>
    </>
  );
};
