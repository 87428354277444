import { useState } from 'react';

import { Input } from '@nextui-org/react';
import { useTranslations } from 'next-intl';

import { useKeys } from '@org/hooks';
import { Button, ModalBody, ModalFooter, ModalHeader, showNotification } from '@org/ui';

import type { ClientModalContentProps } from '..';
import { checkStringForNotAllowedChars, getDuplicatedClientName } from '../utils';

export const AddNewClientModalContent = ({
  disclosureState,
  createClient,
  sortedClients,
}: ClientModalContentProps) => {
  const t = useTranslations();

  const [newClientName, setNewClientName] = useState('');

  const { isOpen, onClose } = disclosureState;

  const handleCloseModal = () => {
    onClose?.();
    setNewClientName('');
  };

  const duplicatedClient = getDuplicatedClientName(newClientName, sortedClients);

  useKeys('Enter', () => {
    const isValid = isOpen && newClientName && !duplicatedClient;

    if (isValid) {
      void createNewClient();
    }
  });

  const createNewClient = async () => {
    if (!newClientName) {
      return;
    }

    if (duplicatedClient) {
      const notificationConfig = {
        clientName: newClientName,
        sharingUsersIdList: duplicatedClient.sharingUsersIdList?.join(', ') ?? t('common.nobody'),
        userId: duplicatedClient.userId,
      };
      showNotification(
        'warning',
        t('main.clients.clientsOverviewPanel.alerts.warning.duplicatedClient', notificationConfig),
        15_000,
      );
      handleCloseModal();

      return;
    }

    try {
      await createClient(newClientName);
      handleCloseModal();
    } catch {
      showNotification('warning', t('main.clients.clientsOverviewPanel.alerts.warning.general'));
    }
  };

  return (
    <>
      <ModalHeader>{t('main.clients.clientsOverviewPanel.header')}</ModalHeader>
      <ModalBody>
        <Input
          label={t('main.clients.clientsOverviewPanel.lblName')}
          onChange={({ target: { value } }) => {
            const notValidChar = checkStringForNotAllowedChars(value);

            if (!notValidChar) {
              setNewClientName(value);
              return;
            }

            showNotification(
              'warning',
              t('common.alerts.warning.notSupportedChar', {
                char: notValidChar,
              }),
            );
          }}
          value={newClientName}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          className="!rounded-md"
          color="danger"
          onClick={handleCloseModal}
          size="md"
          variant="secondary"
        >
          {t('common.close')}
        </Button>
        <Button
          className="!rounded-md"
          isDisabled={!!duplicatedClient || !newClientName}
          onClick={createNewClient}
          size="md"
          variant="primary"
        >
          {t('common.create')}
        </Button>
      </ModalFooter>
    </>
  );
};
