import { useState } from 'react';

import { useTranslations } from 'next-intl';

import { Combobox, ComboboxItem } from '@org/design';
import { Button, ModalBody, ModalFooter, ModalHeader, showNotification } from '@org/ui';

import type { ClientModalContentProps } from '..';

export const ShareClientModalContent = ({
  disclosureState,
  selectedClient,
  users,
  shareClient,
}: ClientModalContentProps) => {
  const t = useTranslations();
  const { clientName = '', sharingUsersIdList = [], userId, id: _id } = selectedClient;
  const { onClose } = disclosureState;

  const [selectedUsers, setSelectedUsers] = useState(sharingUsersIdList);
  const usersToShareWith = users.filter(({ username }) => username !== userId);
  const options = usersToShareWith.map(({ firstName, lastName, username }) => ({
    label: firstName && lastName ? `${firstName} ${lastName}` : username,
    value: username,
  }));

  return (
    <>
      <ModalHeader>
        {t('common.contextMenu.modalShare.title', {
          client: clientName,
        })}
      </ModalHeader>
      <ModalBody>
        {t('common.contextMenu.modalShare.labelAddUser')}

        <Combobox
          aria-label="select users to share with"
          className="mb-2"
          isMultiple
          onSelectionChange={(newValue) => {
            setSelectedUsers([...newValue] as string[]);
          }}
          placeholder={t('common.contextMenu.modalShare.placeholder')}
          selectedKeys={selectedUsers}
        >
          {options.map(({ label, value }) => (
            <ComboboxItem key={value}>{label}</ComboboxItem>
          ))}
        </Combobox>

        <section>
          <div className="mb-2 mt-4">
            {sharingUsersIdList.length > 0
              ? t('common.contextMenu.modalShare.labelClientOwners')
              : t('common.contextMenu.modalShare.labelNotShared')}
          </div>

          <div className="h-[400px] space-y-4 overflow-y-scroll rounded bg-gray-100 p-4">
            {sharingUsersIdList.map((item, index) => (
              <li
                className="flex items-center gap-2"
                key={`${item}${index}`}
              >
                <div className="flex size-8 shrink-0 grow-0 items-center justify-center rounded-full bg-orange-default text-center text-sm text-white">
                  {item.slice(0, 1).toLocaleUpperCase()}
                </div>
                <div className="text-sm font-medium">{item}</div>
              </li>
            ))}
          </div>
        </section>
      </ModalBody>
      <ModalFooter>
        <Button
          className="!rounded-md"
          onClick={onClose}
          size="md"
          variant="secondary"
        >
          {t('common.close')}
        </Button>
        <Button
          className="!rounded-md"
          isDisabled={!clientName}
          onClick={async () => {
            try {
              if (typeof _id === 'number') {
                await shareClient(_id, selectedUsers);
              }
              showNotification(
                'success',
                t('main.clients.clientsOverviewPanel.alerts.success.share', {
                  clientName,
                }),
              );
              onClose?.();
            } catch {
              showNotification(
                'warning',
                t('main.clients.clientsOverviewPanel.alerts.warning.general'),
              );
            }
          }}
          size="md"
          variant="primary"
        >
          {t('common.update')}
        </Button>
      </ModalFooter>
    </>
  );
};
