'use client';

import Link from 'next/link';

import { ClientsOverview } from '@org/pages';
import { Container, Footer } from '@org/ui';

import { ConfigurationBreadcrumbs, Header } from '~/components';
import { CLIENT_PAGE } from '~/urls';
import packageJson from '../../package.json';

export default function Page() {
  return (
    <div className="flex min-h-screen flex-col">
      <Header className="z-30">
        <div className="flex h-8 items-center bg-grey-lighter">
          <Container behavior="fill">
            <ConfigurationBreadcrumbs />
          </Container>
        </div>
      </Header>
      <div className="grid flex-1 overflow-auto">
        <ClientsOverview
          LinkComponent={Link}
          getLinkProps={(clientId) => ({
            href: CLIENT_PAGE({
              clientId,
            }),
          })}
        />
      </div>
      <Footer version={packageJson.version} />
    </div>
  );
}
