import { useState } from 'react';

import { Input } from '@nextui-org/react';
import { useTranslations } from 'next-intl';

import { Button, ModalBody, ModalFooter, ModalHeader, showNotification } from '@org/ui';

import type { ClientModalContentProps } from '..';
import { checkStringForNotAllowedChars, getDuplicatedClientName } from '../utils';

export const RenameClientModalContent = ({
  disclosureState,
  selectedClient,
  updateClient,
  sortedClients,
}: ClientModalContentProps) => {
  const t = useTranslations();
  const { clientName } = selectedClient;
  const { onClose } = disclosureState;

  const [newClientName, setNewClientName] = useState(clientName ?? '');

  const duplicatedClient = getDuplicatedClientName(newClientName, sortedClients);

  const handleCloseModal = () => {
    onClose?.();
    setNewClientName('');
  };

  return (
    <>
      <ModalHeader>
        {t('common.contextMenu.modalUpdate.title', {
          name: clientName,
          object: '',
        })}
      </ModalHeader>
      <ModalBody>
        <Input
          label={t('main.clients.clientsOverviewPanel.lblName')}
          onChange={({ target: { value } }) => {
            const notValidChar = checkStringForNotAllowedChars(value);

            if (!notValidChar) {
              setNewClientName(value);
              return;
            }

            showNotification(
              'warning',
              t('common.alerts.warning.notSupportedChar', {
                char: notValidChar,
              }),
            );
          }}
          value={newClientName}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          className="!rounded-md"
          onClick={handleCloseModal}
          size="md"
          variant="secondary"
        >
          {t('common.btnCancel')}
        </Button>
        <Button
          className="!rounded-md"
          isDisabled={!!duplicatedClient || !newClientName}
          onClick={async () => {
            try {
              await updateClient({ ...selectedClient, clientName: newClientName });
              handleCloseModal();
            } catch {
              showNotification(
                'warning',
                t('main.clients.clientsOverviewPanel.alerts.warning.general'),
              );
            }
          }}
          size="md"
          variant="primary"
        >
          {t('common.update')}
        </Button>
      </ModalFooter>
    </>
  );
};
