import type { FC } from 'react';

import { useTranslations } from 'next-intl';
import { twMerge } from 'tailwind-merge';

import { Container } from '../Container';

type OuterProps = React.JSX.IntrinsicElements['footer'];

export type FooterProps = { version: string } & OuterProps;

export const Footer: FC<FooterProps> = (props) => {
  const { className, version, ...otherProps } = props;
  const t = useTranslations();
  return (
    <footer
      className={twMerge(
        'sticky bottom-0 flex h-10 items-center bg-grey-dark text-xs text-white',
        className,
      )}
      data-testid="Footer"
      {...otherProps}
    >
      <Container
        behavior="fill"
        className="flex-1"
      >
        <div className="flex justify-between gap-4">
          <section>
            <span>{t('main.footer.copyright')}</span>
          </section>

          <section className="grid grid-flow-col gap-2">
            <span>
              <a
                href="https://www.pwc.de/de/disclaimer.html"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('main.footer.disclaimer')}
              </a>
            </span>
            <span>
              <a
                href="https://www.pwc.de/de/impressum.html"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('main.footer.legalNotice')}
              </a>
            </span>
            <span>
              <a
                href="https://www.pwc.de/de/nutzungsbedingungen.html"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('main.footer.termsOfUse')}
              </a>
            </span>
            <span>
              <a
                href="https://www.pwc.de/de/datenschutzerklaerung.html"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('main.footer.privacyPolicy')}
              </a>
            </span>
            <span>{version}</span>
          </section>
        </div>
      </Container>
    </footer>
  );
};
