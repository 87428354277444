import type React from 'react';

import { useDisclosure } from '@nextui-org/react';

import { useSearchNames } from '@org/hooks';
import { aggregated, griddy } from '@org/query';

export interface UseControllerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  LinkComponent: React.ComponentType<any>;
  getLinkProps: (clientName: string) => Record<string, unknown>;
}

export const useController = ({ LinkComponent, getLinkProps }: UseControllerProps) => {
  const { clients, createClient, updateClient, deleteClient, shareClient } = aggregated.useClients(
    {},
  );

  const disclosureState = useDisclosure();

  const { data: users = [] } = griddy.useGetUsers({});

  const { searchedItems, control, handleSearch } = useSearchNames({
    data: clients,
    fieldName: 'clientName',
  });

  const clientsToBeShown = searchedItems ?? clients;

  const sortedClients = clientsToBeShown?.sort((a, b) => {
    if (a.clientName && b.clientName) {
      return a.clientName.localeCompare(b.clientName);
    }
    return 0;
  });

  return {
    LinkComponent,
    control,
    createClient,
    deleteClient,
    disclosureState,
    getLinkProps,
    handleSearch,
    shareClient,
    sortedClients,
    updateClient,
    users,
  };
};

export type ControllerType = ReturnType<typeof useController>;
